import React from 'react';
import { Nav } from '../components/Nav';
import n1 from '../assets/news-001.jpg';

const News1 = () => {
  return (
    <>
      <Nav />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-40'></div>
              <h1 className='title c-theme tc'>Новини</h1>
              <div className='s-40'></div>
            </div>
            <div className='col-lg-10 ma'>
              <img
                src={n1}
                width={640}
                height={320}
                className='w-100 obfc r-8'
                alt='🚨 Зростання кібератак на криптокористувачів під час війни'
              />
              <div className='s-20'></div>
              <h3 className='title c-theme'>
                🚨 Зростання кібератак на криптокористувачів під час війни
              </h3>
              <div className='s-10'></div>
              <p>21 червня 2024 року</p>
              <div className='s-20'></div>
              <p className='title-page'>
                З початком війни в Україні кількість кібератак на користувачів
                криптовалют різко зросла. Військовий конфлікт створює сприятливі
                умови для діяльності хакерів, які намагаються скористатися
                загальною невизначеністю та страхом серед населення. Зловмисники
                активно використовують різні методи, включаючи фішингові атаки,
                зараження пристроїв шкідливим ПЗ, а також компрометацію
                криптогаманців та бірж.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Хакери зазвичай маскуються під офіційні організації чи
                волонтерські ініціативи, які допомагають армії або постраждалим
                від війни. Вони розсилають фальшиві листи з посиланнями на
                шкідливі сайти або створюють підроблені благодійні збори в
                криптовалюті, намагаючись викрасти кошти у довірливих
                користувачів. Це особливо небезпечно в умовах, коли люди масово
                шукають можливості для підтримки своїх рідних та країни.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Крім фішингу, зловмисники активно використовують шкідливе
                програмне забезпечення для зараження комп’ютерів та мобільних
                пристроїв. Таке ПЗ може красти особисті дані, включаючи приватні
                ключі до криптогаманців, паролі від біржових акаунтів та іншу
                конфіденційну інформацію. Після отримання доступу до таких
                даних, хакери можуть безперешкодно виводити криптовалюту на свої
                рахунки.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Фахівці з кібербезпеки закликають українських користувачів бути
                надзвичайно обережними під час здійснення транзакцій у
                криптовалюті та уникати підозрілих листів, посилань та запитів
                на переказ коштів. Використання багаторівневого захисту,
                включаючи двофакторну аутентифікацію та холодні гаманці, може
                значно знизити ризики втрати криптовалют.
              </p>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
      </section>
    </>
  );
};

export { News1 };
