import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { News } from './pages/News';

import { News1 } from './pages/News1';
import { News2 } from './pages/News2';
import { News3 } from './pages/News3';
import { News4 } from './pages/News4';
import { FAQ } from './pages/FAQ';
import { Privacy } from './pages/Privacy';
import { Error } from './pages/Error';
import { Footer } from './components/Footer';

import './styles/custom.sass';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='news' element={<News />} />
        <Route
          path='/news/zrostannya-kiberatak-na-kriptokoristuvachiv-pid-chas-vijni'
          element={<News1 />}
        />
        <Route
          path='/news/ukrayinski-kriptobirzhi-posilyuyut-zahodi-bezpeki'
          element={<News2 />}
        />
        <Route
          path='/news/zbilshennya-shahrajskih-shem-na-tli-vijskovih-dij'
          element={<News3 />}
        />
        <Route
          path='/news/ukrayinski-vijskovi-vikoristovuyut-kriptovalyuti-dlya-zahistu'
          element={<News4 />}
        />
        <Route path='faq' element={<FAQ />} />
        <Route path='privacy-policy' element={<Privacy />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export { App };
