import React from 'react';
import { Nav } from '../components/Nav';
import n2 from '../assets/news-002.jpg';

const News2 = () => {
  return (
    <>
      <Nav />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-40'></div>
              <h1 className='title c-theme tc'>Новини</h1>
              <div className='s-40'></div>
            </div>
            <div className='col-lg-10 ma'>
              <img
                src={n2}
                width={640}
                height={320}
                className='w-100 obfc r-8'
                alt='🛡️ Українські криптобіржі посилюють заходи безпеки'
              />
              <div className='s-20'></div>
              <h3 className='title c-theme'>
                🛡️ Українські криптобіржі посилюють заходи безпеки
              </h3>
              <div className='s-10'></div>
              <p>3 липня 2024 року</p>
              <div className='s-20'></div>
              <p className='title-page'>
                У зв'язку зі зростанням кіберактивності в умовах війни, провідні
                українські криптобіржі змушені впроваджувати нові, більш жорсткі
                заходи безпеки. Вони покликані захистити користувачів від
                зловмисників, які намагаються скористатися ситуацією для
                незаконного отримання цифрових активів. Один із ключових
                аспектів нових заходів — обов’язкове запровадження двофакторної
                аутентифікації (2FA) для всіх користувачів.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Завдяки 2FA користувачі можуть додатково захистити свої акаунти,
                оскільки доступ до них стає можливим лише після підтвердження
                через інший пристрій або додаток. Цей метод значно ускладнює
                роботу хакерам, які не мають фізичного доступу до другого
                фактору аутентифікації.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Крім того, криптобіржі впроваджують покращені системи
                моніторингу транзакцій. Ці системи аналізують поведінку
                користувачів і виявляють будь-які підозрілі активності,
                наприклад, спроби виведення великої кількості коштів на
                нещодавно створені гаманці або несанкціоновані доступи з
                незвичних локацій. В разі виявлення підозрілих дій, система може
                автоматично блокувати транзакцію та повідомляти користувача про
                можливу загрозу.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Зміни також стосуються посилення процедур верифікації нових
                користувачів. Це включає більш ретельну перевірку документів і
                додаткові заходи для запобігання відмиванню грошей та
                фінансуванню тероризму, що особливо важливо в умовах нинішньої
                військової ситуації.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Загалом, ці заходи спрямовані на те, щоб зробити користування
                криптовалютними біржами в Україні безпечнішим, навіть в умовах
                зростаючих кіберзагроз. Користувачам радять постійно слідкувати
                за новими оновленнями безпеки та не нехтувати додатковими
                засобами захисту своїх цифрових активів.
              </p>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
      </section>
    </>
  );
};

export { News2 };
