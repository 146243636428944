import React from 'react';

import pic from '../../assets/intro-pic.jpg';

const Intro = () => {
  return (
    <section id='top'>
      <div className='container'>
        <div className='s-80'></div>
        <img src={pic} alt='feature' />
        <div className='s-40'></div>
        <p className='title c-theme'>
          Навчайтеся та отримуйте консультації щодо криптовалют разом з нами!
        </p>
        <div className='s-20'></div>
        <p className='title-sub'>
          Відкрийте для себе світ криптовалют та дізнайтеся, як безпечно та
          ефективно використовувати їх у своєму житті.
        </p>
        <p>
          Ми пропонуємо безкоштовні консультації та навчальні програми, які
          допоможуть вам розібратися в тонкощах крипторинку. З нами ви зможете
          отримати всі необхідні знання та навички для успішного використання
          криптовалюти.
        </p>
        <div className='s-10'></div>
        <a
          href='https://forms.gle/4nA3v4tKXY5Jgns56'
          target='blank'
          className='button'
        >
          Приєднатися
        </a>
        <div className='s-40'></div>
      </div>
    </section>
  );
};

export { Intro };
