import React from 'react';
import { Nav } from '../components/Nav';
import n3 from '../assets/news-003.jpg';

const News3 = () => {
  return (
    <>
      <Nav />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-40'></div>
              <h1 className='title c-theme tc'>Новини</h1>
              <div className='s-40'></div>
            </div>
            <div className='col-lg-10 ma'>
              <img
                src={n3}
                width={640}
                height={320}
                className='w-100 obfc r-8'
                alt='🔐 Збільшення шахрайських схем на тлі військових дій'
              />
              <div className='s-20'></div>
              <h3 className='title c-theme'>
                🔐 Збільшення шахрайських схем на тлі військових дій
              </h3>
              <div className='s-10'></div>
              <p>14 липня 2024 року</p>
              <div className='s-20'></div>
              <p className='title-page'>
                В умовах війни в Україні спостерігається різке збільшення
                кількості шахрайських схем у сфері криптовалют. Зловмисники
                активно використовують ситуацію нестабільності, намагаючись
                скористатися людською добротою та бажанням допомогти
                постраждалим від війни. Однією з найпоширеніших схем є створення
                підроблених благодійних організацій або волонтерських фондів, що
                нібито збирають криптовалюту для підтримки армії або допомоги
                мирному населенню.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Ці фальшиві ініціативи можуть виглядати дуже переконливо — вони
                використовують реальні фото, відео та навіть підроблені
                документи, щоб завоювати довіру користувачів. Часто шахраї
                маскують свої сайти під відомі благодійні платформи, додаючи
                туди криптовалютні гаманці, на які пропонують перераховувати
                допомогу. Після отримання коштів такі сайти швидко зникають, а
                зловмисники залишаються з криптовалютою, яку важко відстежити.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Ще однією популярною схемою є розсилка фальшивих листів або
                повідомлень, де просять допомогти постраждалим, надаючи
                посилання на підроблені платформи для збору коштів. У таких
                повідомленнях зазвичай вказують на терміновість та важливість
                допомоги, щоб змусити людей діяти швидко, не перевіряючи
                достовірність інформації.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Фахівці з кібербезпеки радять громадянам ретельно перевіряти
                організації, до яких вони збираються перераховувати кошти.
                Важливо звертати увагу на офіційні канали зв’язку, репутацію
                платформи, а також уникати надання допомоги через невідомі або
                підозрілі ресурси. Використання надійних криптогаманців та
                багаторівневого захисту також може знизити ризик стати жертвою
                шахраїв.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Попри всі труднощі, що виникли у зв’язку з війною, користувачам
                варто пам'ятати про важливість збереження безпеки своїх коштів і
                ретельно перевіряти будь-яку інформацію, особливо якщо вона
                стосується фінансових транзакцій.
              </p>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
      </section>
    </>
  );
};

export { News3 };
