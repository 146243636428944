import React from 'react';
import pic1 from '../../assets/rw-001.jpg';
import pic2 from '../../assets/rw-002.jpg';

const Content = () => {
  return (
    <section id='top'>
      <div className='container'>
        <div className='s-80'></div>
        <h2 className='title c-theme tc'>Що ми пропонуємо:</h2>
        <div className='s-40'></div>
        <p className='box box-1'>
          Консультації щодо криптовалют: Отримайте індивідуальні поради від
          досвідчених фахівців, які допоможуть вам зрозуміти основи та уникнути
          ризиків.
        </p>
        <div className='s-20'></div>
        <p className='box box-2'>
          Навчання криптовалютам: Дізнайтеся, як працюють криптовалюти, як їх
          купувати, зберігати та використовувати з вигодою для себе.
        </p>
        <div className='s-20'></div>
        <p className='box box-3'>
          Комбіновані програми: Поєднуйте навчання з консультаціями, щоб
          отримати повний набір знань та практичних навичок.
        </p>
        Приєднуйтесь до нас, і ми допоможемо вам освоїти криптовалюту та
        безпечно працювати з нею.
        <div className='s-80'></div>
        <h2 className='title c-theme tc w-80 ma'>
          Наш підхід до підтримки та розвитку криптовалютної спільноти
        </h2>
        <div className='s-40'></div>
        <p className='px-5 c-dark tc'>
          <strong>
            Ми створюємо платформу, яка спрямована на розвиток знань та
            практичних навичок у сфері криптовалют. Наш підхід базується на
            залученні експертів, які готові поділитися своїм досвідом та
            допомогти кожному, хто прагне зрозуміти і використати криптовалюти
            для свого блага.
          </strong>
        </p>
        <div className='s-40'></div>
        <p className='box box-1 mx-5 title-sub'>Реальні умови для розвитку</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми надаємо можливість всім учасникам поглибити свої знання у сфері
          криптовалют, відпрацьовуючи навички в умовах, максимально наближених
          до реальних ринкових ситуацій.
        </p>
        <div className='s-20'></div>
        <p className='box box-2 mx-5 title-sub'>Значущі знання та навички</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Наші програми зосереджені на актуальних питаннях, які виникають у
          світі криптовалют. Ми допомагаємо розібратися в ключових аспектах, що
          мають реальний вплив на безпеку та ефективність роботи з цифровими
          активами.
        </p>
        <div className='s-20'></div>
        <p className='box box-3 mx-5 title-sub'>Підтримка та наставництво</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми забезпечуємо учасників підтримкою досвідчених фахівців, які
          допоможуть не лише у професійному розвитку, але й у вирішенні складних
          питань, пов'язаних із криптовалютами.
        </p>
        <div className='s-20'></div>
        <p className='box box-1 mx-5 title-sub'>Внесок у криптоспільноту</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми об'єднуємо зусилля наших учасників та експертів для того, щоб
          розвивати спільноту та сприяти безпечному використанню криптовалют у
          різних сферах життя.
        </p>
        <div className='s-40'></div>
      </div>
      <div className='s-80'></div>
      <h2 className='title c-theme tc w-80 ma'>
        Наш вплив на професійний розвиток учасників
      </h2>
      <div className='s-40'></div>
      <p className='px-5 c-dark tc mx-5'>
        <strong>
          Ми горді тим, що можемо надати платформу для тих, хто хоче реалізувати
          свої навички та внести свій вклад у соціально значущі проекти. Ось
          декілька історій успіху від наших учасників, які змогли покращити свої
          професійні навички та знайти своє місце в галузі.
        </strong>
      </p>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-4'>
            <div className='s-40'></div>
            <img
              src={pic1}
              alt='Олександр Мельник'
              width={480}
              height={480}
              className='w-100 r-24 obfc'
            />
            <div className='s-20'></div>
            <h4 className='title-sub'>Олександр Мельник</h4>
            <p>Підприємець</p>
            <div className='s-20'></div>
            <p>
              Завдяки навчальній програмі з криптовалют я зміг поглибити свої
              знання про блокчейн та крипторинки. Інформація була структурована
              і легко засвоювалася, що дозволило мені впевнено розпочати
              інвестиції. Рекомендую всім, хто хоче зануритися в світ
              криптовалют і зробити це з мінімальними ризиками!
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-40'></div>
            <img
              src={pic2}
              alt='Ірина Коваль'
              width={480}
              height={480}
              className='w-100 r-24 obfc'
            />
            <div className='s-20'></div>
            <h4 className='title-sub'>Ірина Коваль</h4>
            <p>Фінансовий консультант</p>
            <div className='s-20'></div>
            <p>
              Я звернулася за консультацією щодо безпечного використання
              криптовалют, і отримала вичерпні відповіді на всі мої питання.
              Консультант надав практичні поради, які допомогли мені уникнути
              ризиків та краще зрозуміти принципи роботи крипторинку. Дуже
              вдячна за професійний підхід!
            </p>
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
      <div className='container'>
        <div className='s-80'></div>
        <h2 className='title c-theme tc w-80 ma'>
          Станьте частиною нашого ком'юніті та розкрийте свій потенціал
        </h2>
        <div className='s-40'></div>
        <p className='px-5 c-dark tc mx-5'>
          <strong>
            Приєднавшись до нас, ви отримаєте можливість не тільки розширити
            свої знання, а й зробити свій внесок у розвиток криптовалютної
            спільноти. Ми шукаємо мотивованих людей, які прагнуть розвиватися та
            допомагати іншим освоювати криптовалюти.
          </strong>
        </p>
        <div className='s-40'></div>
        <p className='box box-1 mx-5 title-sub'>Ключові навички та знання</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми цінуємо ваші знання у сфері криптовалют, технологій блокчейну та
          управління цифровими активами, які сприятимуть успішному розвитку
          наших програм.
        </p>
        <div className='s-20'></div>
        <p className='box box-2 mx-5 title-sub'>Менторство та підтримка</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Наші ментори та фахівці забезпечують повну підтримку, допомагаючи
          ефективно засвоювати нові знання та успішно застосовувати їх на
          практиці.
        </p>
        <div className='s-20'></div>
        <p className='box box-3 mx-5 title-sub'>
          Робота над актуальними завданнями
        </p>
        <div className='s-20'></div>
        <p className='px-5'>
          Учасники матимуть можливість працювати над реальними питаннями
          криптовалютного ринку, отримуючи практичний досвід, що вплине на їхній
          подальший розвиток у цій сфері.
        </p>
        <div className='s-20'></div>
        <p className='box box-1 mx-5 title-sub'>Співтовариство однодумців</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Долучившись до нашої спільноти, ви зможете взаємодіяти з іншими
          талановитими та амбітними людьми, які поділяють ваше бажання розвивати
          криптовалюту та використовувати її потенціал для створення позитивних
          змін.
        </p>
        <div className='s-80'></div>
        <div className='tc'>
          <a
            href='https://forms.gle/4nA3v4tKXY5Jgns56'
            target='blank'
            className='button'
          >
            Дізнатися більше та приєднатися
          </a>
        </div>
        <div className='s-100'></div>
      </div>
    </section>
  );
};

export { Content };
