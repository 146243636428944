import React from 'react';
import { Nav } from '../components/Nav';
import n4 from '../assets/news-004.jpg';

const News4 = () => {
  return (
    <>
      <Nav />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-40'></div>
              <h1 className='title c-theme tc'>Новини</h1>
              <div className='s-40'></div>
            </div>
            <div className='col-lg-10 ma'>
              <img
                src={n4}
                width={640}
                height={320}
                className='w-100 obfc r-8'
                alt='🛡️ Українські військові використовують криптовалюти для захисту'
              />
              <div className='s-20'></div>
              <h3 className='title c-theme'>
                🛡️ Українські військові використовують криптовалюти для захисту
              </h3>
              <div className='s-10'></div>
              <p>29 липня 2024 року</p>
              <div className='s-20'></div>
              <p className='title-page'>
                Українські військові під час війни активно звернулися до
                криптовалют як одного з інструментів фінансування оборонних
                операцій. В умовах, коли традиційні фінансові системи можуть
                бути вразливими до атак або блокування, криптовалюти надають
                безпечний і децентралізований спосіб забезпечити безперебійне
                фінансування.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Зокрема, військові створили декілька децентралізованих платформ,
                що дозволяють проводити фінансові транзакції в умовах бойових
                дій. Завдяки цим платформам стало можливим швидко й безпечно
                перераховувати кошти на закупівлю необхідного обладнання,
                медичних засобів та інших ресурсів, які критично важливі для
                забезпечення обороноздатності країни. Використання
                блокчейн-технологій також дозволяє уникнути перехоплення
                транзакцій ворогом, що знижує ризики для операцій.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Також криптовалюти стали інструментом для міжнародної підтримки
                України. Багато волонтерських організацій і приватних осіб з
                усього світу перераховують допомогу українській армії саме у
                криптовалюті, що дозволяє уникнути складних банківських процедур
                і прискорити процес отримання коштів. Це має вирішальне значення
                в умовах, коли кожна хвилина на вагу золота.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Крім того, криптовалюти надають додаткову гнучкість у фінансових
                операціях, що дозволяє військовим швидко адаптуватися до змін у
                ситуації на фронті. Зниження ризику санкційного чи іншого
                зовнішнього впливу на транзакції також є важливим фактором для
                забезпечення стабільності фінансових потоків.
              </p>
              <div className='s-20'></div>
              <p className='title-page'>
                Таким чином, криптовалюти стали важливою частиною фінансової
                стратегії українських військових, дозволяючи забезпечити
                безперебійну підтримку фронту в умовах складної ситуації та
                численних загроз.
              </p>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
      </section>
    </>
  );
};

export { News4 };
