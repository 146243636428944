import React from 'react';
import { Nav } from '../components/Nav';
import n1 from '../assets/news-001.jpg';
import n2 from '../assets/news-002.jpg';
import n3 from '../assets/news-003.jpg';
import n4 from '../assets/news-004.jpg';

const News = () => {
  return (
    <>
      <Nav />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-40'></div>
              <h1 className='title c-theme tc'>Новини</h1>
              <div className='s-40'></div>
            </div>
            <div className='col-lg-6 px-4' style={{ marginBottom: 80 }}>
              <a href='/news/zrostannya-kiberatak-na-kriptokoristuvachiv-pid-chas-vijni'>
                <img
                  src={n1}
                  width={640}
                  height={320}
                  className='w-100 obfc r-8'
                  alt='🚨 Зростання кібератак на криптокористувачів під час війни'
                />
              </a>
              <div className='s-20'></div>
              <h3 className='title-page c-theme h-10'>
                🚨 Зростання кібератак на криптокористувачів під час війни
              </h3>

              <p className='title-page h-20'>
                Кількість кібератак на українських користувачів криптовалют
                значно зросла через військові дії. Зловмисники активно
                використовують фішингові атаки та шкідливе ПЗ для викрадення
                цифрових активів.
              </p>
              <div className='s-10'></div>
              <p>21 червня 2024 року</p>
              <div className='s-10'></div>
              <a
                href='/news/zrostannya-kiberatak-na-kriptokoristuvachiv-pid-chas-vijni'
                className='button'
              >
                Детальнiше
              </a>
            </div>
            <div className='col-lg-6 px-4' style={{ marginBottom: 80 }}>
              <a href='/news/ukrayinski-kriptobirzhi-posilyuyut-zahodi-bezpeki'>
                <img
                  src={n2}
                  width={640}
                  height={320}
                  className='w-100 obfc r-8'
                  alt='🛡️ Українські криптобіржі посилюють заходи безпеки'
                />
              </a>
              <div className='s-20'></div>
              <h3 className='title-page c-theme h-10'>
                🛡️ Українські криптобіржі посилюють заходи безпеки
              </h3>

              <p className='title-page h-20'>
                Відповідаючи на зростаючі загрози, українські криптовалютні
                біржі вводять додаткові заходи безпеки. Запроваджуються
                обов’язкові двофакторні аутентифікації та покращені системи
                моніторингу.
              </p>
              <div className='s-10'></div>
              <p>3 липня 2024 року</p>
              <div className='s-10'></div>
              <a
                href='/news/ukrayinski-kriptobirzhi-posilyuyut-zahodi-bezpeki'
                className='button'
              >
                Детальнiше
              </a>
            </div>
            <div className='col-lg-6 px-4' style={{ marginBottom: 80 }}>
              <a href='/news/zbilshennya-shahrajskih-shem-na-tli-vijskovih-dij'>
                <img
                  src={n3}
                  width={640}
                  height={320}
                  className='w-100 obfc r-8'
                  alt='🔐 Збільшення шахрайських схем на тлі військових дій'
                />
              </a>
              <div className='s-20'></div>
              <h3 className='title-page c-theme h-10'>
                🔐 Збільшення шахрайських схем на тлі військових дій
              </h3>

              <p className='title-page h-20'>
                Через військові дії в Україні зростає кількість шахрайських схем
                з криптовалютами. Зловмисники використовують благодійні збори як
                прикриття для викрадення коштів.
              </p>
              <div className='s-10'></div>
              <p>14 липня 2024 року</p>
              <div className='s-10'></div>
              <a
                href='/news/zbilshennya-shahrajskih-shem-na-tli-vijskovih-dij'
                className='button'
              >
                Детальнiше
              </a>
            </div>
            <div className='col-lg-6 px-4' style={{ marginBottom: 80 }}>
              <a href='/news/ukrayinski-vijskovi-vikoristovuyut-kriptovalyuti-dlya-zahistu'>
                <img
                  src={n4}
                  width={640}
                  height={320}
                  className='w-100 obfc r-8'
                  alt='🛡️ Українські військові використовують криптовалюти для захисту'
                />
              </a>
              <div className='s-20'></div>
              <h3 className='title-page c-theme h-10'>
                🛡️ Українські військові використовують криптовалюти для захисту
              </h3>

              <p className='title-page h-20'>
                Українські військові активно використовують криптовалюти для
                фінансування своїх операцій. Вони створюють децентралізовані
                платформи, що дозволяють захищати фінансові транзакції від
                перехоплення ворогом.
              </p>
              <div className='s-10'></div>
              <p>29 липня 2024 року</p>
              <div className='s-10'></div>
              <a
                href='/news/ukrayinski-vijskovi-vikoristovuyut-kriptovalyuti-dlya-zahistu'
                className='button'
              >
                Детальнiше
              </a>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
      </section>
    </>
  );
};

export { News };
